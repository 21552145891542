var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $contentErrors = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileNumberInput = $('input[name="MOBILE_PHONE"]', $emailContainerNode);
        var $termsAndConditions = $('.js-terms-conditions', $emailContainerNode);
        var $emailError = $('.site-email-signup__message__error__email_address', $emailContainerNode);
        var $phoneError = $('.site-email-signup__message__error__mobile_phone', $emailContainerNode);
        var $genericError = $('.site-email-signup__message__error__generic', $emailContainerNode);
        var locale = generic.cookie('LOCALE');
        var language_id;
        var lang_configuration = Drupal.settings.common.lang_config_ids;

        if (locale in lang_configuration) {
          language_id = lang_configuration[locale];
          $('input[name=LANGUAGE_ID]').val(language_id);
        }

        // Show terms + conditions for mobile only
        $emailInput.on('focus', function (e) {
          $termsAndConditions.slideDown(300);
        });

        $emailForm.once('js-email-signup').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $emailInput.removeClass('error');
          $mobileNumberInput.removeClass('error');
          $emailError.addClass('hidden');
          $phoneError.addClass('hidden');
          $genericError.addClass('hidden');
          $emailError.removeClass('error');
          $phoneError.removeClass('error');
          $genericError.removeClass('error');

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if ($emailContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          } else {
            params.SMS_PROMOTIONS = 0;
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
              } else {
                $(document).trigger('email_signup:success_and_new');
              }

              if ($emailSuccess.hasClass('inline')) {
                $emailSuccess.removeClass('hidden');
              } else {
                $emailInput.val('');
                $mobileNumberInput.val('');
                $termsAndConditions.slideUp(0);
                generic.overlay.launch({
                  height: 'auto',
                  html: $emailSuccess.html()
                });
              }
            },
            onFailure: function () {
              var $genericMessage = true;

              $contentErrors.removeClass('hidden');
              if ($emailInput.val().length === 0) {
                $emailInput.addClass('error');
                $emailError.removeClass('hidden');
                $emailError.addClass('error');
                $genericMessage = false;
              }
              // var smsOpt = $('input[name="SMS_PROMOTIONS"]').is(':checked');
              // if ($mobileNumberInput.length > 0) {
              //   if ($mobileNumberInput.val().length === 0 || !smsOpt) {
              //     $mobileNumberInput.addClass('error');
              //     $phoneError.removeClass('hidden');
              //     $phoneError.addClass('error');
              //     $genericMessage = false;
              //   }
              // }
              if ($genericMessage == true) {
                $emailInput.addClass('error');
                $mobileNumberInput.addClass('error');
                $genericError.removeClass('hidden');
                $genericError.addClass('error');
              }
            }
          });
        });
      });
    },

    gdprRemInfoMessageBox: function () {
      if ($('.info-msg-gdpr').get(0)) {
        $('.info-msg-gdpr').remove();
      }
    },

    gdprSetInfoBoxPosition: function (infoLink, infoMessageBox, infoMsgArrow, isMobile) {
      var infoLinkTop = infoLink.offset().top + infoLink.height() + 10;
      var infoLinkLeft = infoLink.offset().left;
      var infoMsgMaxWidth = parseInt(infoMessageBox.css('max-width').replace('px', ''));

      if (isMobile) {
        infoMsgArrow.css({
          left: (infoLinkLeft - 10) + 'px'
        });
        infoLinkLeft = 10;
      } else if ((infoMessageBox.outerWidth(true) === infoMsgMaxWidth) && ((infoLinkLeft + infoMsgMaxWidth) > screen.width)) {
        infoLinkLeft = infoLinkLeft - 480;
        infoMsgArrow.addClass('top-right-arrow');
      }
      infoMessageBox.css({
        top: infoLinkTop + 'px',
        left: infoLinkLeft + 'px'
      });
    },

    gdprToolTip: function (context) {
      var isMobile = Unison.fetch.now().name === 'small';

      $('body', context).click(function (e) {
        if (e.target.className !== 'info-msg-gdpr' && e.target.className !== 'info-msg-gdpr__txt') {
          $('.info-msg-gdpr').addClass('hidden');
        }
      });
      $('.js-info-link-gdpr', context).live('click', function (e) {
        e.preventDefault();
        var infoLink = $(e.currentTarget);

        site.emailSignup.gdprRemInfoMessageBox();

        $("<div class='info-msg-gdpr'><div class='info-msg-gdpr__arrow'></div><div class='info-msg-gdpr__txt js-info-msg-gdpr__txt'></div></div>").appendTo('body');
        var infoBoxEle = $('#' + infoLink.attr('message-in'));
        var infoMessageBox = $('.info-msg-gdpr');
        var infoMsgArrow = $('.info-msg-gdpr__arrow');

        $('.info-msg-gdpr__txt').html("<div class='info-msg-gdpr__close js-info-msg-gdpr__close'>X</div>" + infoBoxEle.html());

        $('#cboxClose, .js-info-msg-gdpr__close', context).live('click', function (e) {
          e.stopPropagation();
          site.emailSignup.gdprRemInfoMessageBox();
        });

        site.emailSignup.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile);
        $(window).on('resize-debounced', site.emailSignup.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile));

        $('.js-info-msg-gdpr__txt a').live('mousedown', function (e) {
          // Reload only on left click
          if (e.which === 1) {
            window.location = $(this).attr('href');
          }
        });
      });
    }

  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
      site.emailSignup.gdprToolTip(context);
      var $forgotPassword = $('.js-forgot-password', context);
      var $signinEmailAddress = $('.return-user-email', context);

      if ($forgotPassword.length) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $signinEmailAddress,
          errorListNode: ' ',
          forgotPasswordLink: $forgotPassword
        });
      }
    }
  };
})(jQuery);
